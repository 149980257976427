import { Link } from "gatsby";
import React, { useEffect } from "react";

import logo from "../../../../static/svg/TSD_Logo.svg";
import accessibieIcon from "../../../images/icons/TSD_Accessi.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  useEffect(() => {
    document.body.classList.remove("no-sroll");
  }, []);

  const onClick = () => {
    document.body.classList.toggle("no-sroll");
  };

  return (
    <header id="masthead">
      <Navbar expand="lg">
        <Container>
          <Link to="/" className="logo">
            <img
              src={logo}
              alt="The Simple Department"
              title="The Simple Department"
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={onClick} />
          <Navbar.Collapse id="basic-navbar-nav" className="main-nav">
            <Nav className="">
              <Nav.Link href="/" className="mobile-menu-home-link">
                Home
              </Nav.Link>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item href="/services/websites">
                  Websites
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/seo">
                  SEO services
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/content">
                  Content + Partnerships
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/cmo">
                  Fractional CMO
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/support">
                  Web + IT Support
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/advertising">
                  Digital Advertising
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/design-services">
                  Design Services
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/email-automated-marketing-services">
                  Email Marketing
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/how-we-work" className="navbar-item">
                How We Work
              </Nav.Link>
              <Nav.Link href="/blog" className="navbar-item">
                Blog
              </Nav.Link>
              <Nav.Link
                href="https://app.clickup.com/login"
                target="_blank"
                rel="noreferrer"
                className="btn btn-white mob-login-btn"
              >
                Login to your project
              </Nav.Link>
              <span
                className="navbar-item accessibility-item"
                data-acsb-custom-trigger="true"
              >
                <img
                  className="accessibility-icon"
                  src={accessibieIcon}
                  alt="Accessibility Icon"
                />
              </span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
