import React from "react";
import SocialMedia from "../../social-media/social-media";
import logo from "~static/svg/icons/footer_icon.svg";

import img_2 from "~images/components/base/footer/footer-2.jpg";
import img_3 from "~images/components/base/footer/footer-3.jpg";
import img_4 from "~images/components/base/footer/footer-4.jpg";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-col col-one">
            <div className="col-inner">
              <img
                src={logo}
                width="70"
                className="footer-logo"
                alt="Hot Soup Marketing Lab"
                title="Hot Soup Marketing Lab"
              />

              <ul className="footer-nav">
                <li>
                  <a href="/how-we-work/">How we work</a>
                </li>
                <li>
                  <a
                    href="https://app.clickup.com/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login to your project
                  </a>
                </li>
              </ul>
              <div className="hero-txt">
                Let’s get started on your next project!
              </div>
              <p className="offer-txt">
                We offer subscription-based services and commitment-free
                consulting to help you with your marketing needs, while keeping
                costs low.
              </p>
            </div>
          </div>
          <div className="footer-col col-two">
            <div className="col-inner">
              <h5>SEE WHAT WE’RE UP TO</h5>

              <div className="wwu-wrapper">
                <div className="wwu-item">
                  <img src={img_2} alt="" />
                </div>
                <div className="wwu-item">
                  <img src={img_3} alt="" />
                </div>
                <div className="wwu-item">
                  <img src={img_4} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-col col-three">
            <div className="col-inner col-three">
              <h5>FOLLOW US</h5>
              {SocialMedia}
              <ul className="footer-nav">
                <li>
                  <a
                    href="https://app.clickup.com/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login to your project
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          © {new Date().getFullYear()} THE SIMPLE DEPARTMENT
        </div>
      </div>
    </footer>
  );
};

export default Footer;
